import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"

import Layout from "../layout/Layout"
import Seo from "../components/Seo"

import Card from "../components/Card"
import respond from "../styles/abstracts/mediaqueries"

const StyledThankYou = styled.main`
  h1 {
    color: darkgreen;
    text-transform: uppercase;
  }

  .card-container {
    width: 70%;
    margin: 0 auto;

    ${respond(
      "ipad-pro-12-port",
      css`
        width: 80%;
      `
    )}
    ${respond(
      "ipad-pro-11-port",
      css`
        width: 90%;
      `
    )}
    ${respond(
      "ipad-port",
      css`
        width: 100%;
      `
    )}
  }

  p {
    width: 100%;
  }
`

const ThankYouPage = props => {
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    const first_name = props?.location?.state?.formData?.first_name
    if (!first_name) return
    if (!isBrowser) return
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }, [props?.location?.state?.formData?.first_name, isBrowser])

  const first_name = props?.location?.state?.formData?.first_name
  if (!first_name && isBrowser) {
    navigate("/")
    return ""
  }

  // const first_name = "Mattia"

  return (
    <Layout innerLayout>
      <Seo title="Giovanni Setti | Grazie per Aver Inviato la Tua Candidatura" />
      <StyledThankYou className="container">
        <Card>
          <h1>
            COMPLIMENTI {first_name}, <br />
            LA TUA CANDIDATURA È STATA ACCETTATA!
          </h1>
          <div className="card-container">
            <div className="text-container">
              <p>
                Complimenti {first_name || ""}, la tua candidatura è stata
                accettata e puoi accedere alla consulenza gratuita con Giovanni
                Setti!
              </p>
              <p>
                Programma subito l'appuntamento tramite l'applicazione qui
                sotto:
              </p>
            </div>
            {/* <!-- Calendly inline widget begin --> */}
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/giovannisetticonsulting/consulenzagratuita?text_color=5b657e&primary_color=1d7aed"
              style={{ minWidth: "100%", width: "100%", height: "1200px" }}
            ></div>
            {/* <!-- Calendly inline widget end --> */}
          </div>
        </Card>
      </StyledThankYou>
    </Layout>
  )
}

export default ThankYouPage
